import { useEffect } from "react";
import { toast } from "react-toastify";
import { ExClient } from "../ExClient";
import { useStoreMain } from "../store/useStoreMain";
import {
  selectInit,
  selectSetIsAuthorized,
} from "../store/selectors/MainSelectors";
import { CLIENT_EVENTS, EventUtils } from "../utils/EventUtils";

const LOGGED_OUT_MSG = `You've been logged out due inactivity for security reasons.`;

export const useApp = () => {
  const init = useStoreMain(selectInit);
  const setIsAuthorized = useStoreMain(selectSetIsAuthorized);

  useEffect(() => {
    init().then(() => {
      ExClient.init();
      if (sessionStorage.getItem("loggedOut") === "1") {
        toast.info(LOGGED_OUT_MSG);
        sessionStorage.removeItem("loggedOut");
      }
    });

    const unsubscribeClientLoggedOutEvent = EventUtils.bus.subscribe(
      CLIENT_EVENTS.clientLoggedOutEvent,
      (event: any) => {
        toast.info(LOGGED_OUT_MSG);
        setIsAuthorized(false);
      }
    );

    return () => unsubscribeClientLoggedOutEvent();
  }, [init]);
};

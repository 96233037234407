import { Component } from "react";
import { CLIENT_EVENTS, EventUtils } from "./utils/EventUtils";
import { ExClientInfo } from "./ExClientInfo";

export class ExClientInfoComponent extends Component<{}, ExClientInfo> {
    state = new ExClientInfo()

    unsubscribeClientInfoEvent: () => void;

    componentDidMount(): void {
        this.unsubscribeClientInfoEvent = EventUtils.bus.subscribe(CLIENT_EVENTS.clientInfoUpdateEvent, (event) => {
            console.log(event.payload)
            this.setState(event.payload)
        });
    }

    public componentWillUnmount(): void {
        this.unsubscribeClientInfoEvent();
    }
}
import { useCallback } from "react";
import { toast } from "react-toastify";
import { CombinedError } from "@urql/core";
import { useStoreCardView } from "../../store/useStoreCardView";
import { CardStatus, MutationType } from "../../types/CardTypes";

type Props<
  Block extends object,
  Close extends object,
  Unblock extends object
> = {
  block: () => MutationType<Block, { id: string }>;
  close: () => MutationType<Close, { id: string }>;
  unblock: () => MutationType<Unblock, { id: string }>;
  onComplete?: () => void;
  msgClosed?: string;
  msgBlocked?: string;
  msgUnblocked?: string;
};

type Return = {
  fetching: boolean;
  isClosed: boolean;
  isBlocked: boolean;
  cardNumber: string;
  closeCard: () => void;
  blockCard: () => void;
  unblockCard: () => void;
};

function checkResponse(
  error: CombinedError,
  message: string,
  onComplete: () => void
) {
  onComplete();
  if (typeof error !== "undefined") return toast.error(error.message);
  toast.success(message);
}

export const useCardActions = <
  BlockCard extends object,
  CloseCard extends object,
  UnblockCard extends object
>({
  block,
  close,
  unblock,
  onComplete = () => {},
  msgClosed = "Card has been closed.",
  msgBlocked = "Card has been frozen.",
  msgUnblocked = "Card has been unfrozen.",
}: Props<BlockCard, CloseCard, UnblockCard>): Return => {
  const { id, maskedCardNumber: cardNumber, status } = useStoreCardView(
    (state) => state.card
  );

  const [{ fetching: fetchingClose }, closeCardMutation] = close();

  const [{ fetching: fetchingBlock }, blockCardMutation] = block();
  const [{ fetching: fetchingUnblock }, unblockCardMutation] = unblock();

  const closeCard = useCallback(async () => {
    const { error } = await closeCardMutation({ id });
    checkResponse(error, msgClosed, onComplete);
  }, [id, closeCardMutation, onComplete, msgClosed]);

  const blockCard = useCallback(async () => {
    const { error } = await blockCardMutation({ id });
    checkResponse(error, msgBlocked, onComplete);
  }, [id, blockCardMutation, onComplete, msgBlocked]);

  const unblockCard = useCallback(async () => {
    const { error } = await unblockCardMutation({ id });
    checkResponse(error, msgUnblocked, onComplete);
  }, [id, unblockCardMutation, onComplete, msgUnblocked]);

  return {
    fetching: fetchingBlock || fetchingUnblock || fetchingClose,
    isClosed: status === CardStatus.Closed,
    isBlocked: status === CardStatus.Blocked,
    cardNumber,
    closeCard,
    blockCard,
    unblockCard,
  };
};

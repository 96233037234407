import { useEffect, useMemo } from 'react'
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone'

type ExchangeStep3UploadProps = {
  file?: File
  setFile: (file: File) => void
  baseStyle?: {}
  activeStyle?: {}
  acceptStyle?: {}
  rejectStyle?: {}
}

type ExchangeStep3UploadReturn = {
  style: {}
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps
}

export const useExchangeStep3Upload = ({
  file,
  setFile,
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
}: ExchangeStep3UploadProps): ExchangeStep3UploadReturn => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png']
    },
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const acceptedFile = acceptedFiles[0]
        setFile(acceptedFile)
      } else setFile(null)
    }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragActive, isDragReject, isDragAccept])

  useEffect(() => {
    if (file) URL.revokeObjectURL(file['preview'])
  }, [file])

  return {
    style,
    getRootProps,
    getInputProps,
  }
}

export const getValue4Select = (options: { [_: string]: string }[], selected: string, isMulti = false) => {
  selected = selected ? selected.toString() : ''
  if (!options)
    return null

  if (!isMulti)
    return options.find(el => el.value.toString() === selected)

  return options.filter(el => selected.split(',').includes(el.value.toString()))
}

import { ExClient } from '../ExClient'
import { StringUtils } from '../utils/StringUtils'
import { ExchangeAmountLimit } from '../types/ExchangeTypes'

export function validateAmount(value: string, msgFieldRequired: string): string {
  if (value === '')
    return msgFieldRequired

  const amount = parseFloat(value)
  if (isNaN(amount) || amount <= 0)
    return 'Value should be greater than 0.'

  return ''
}

export function validateCryptoAmount(value: string, cryptoCurrency: string, limit: ExchangeAmountLimit, msgFieldRequired: string): string {
  if (value === '')
    return msgFieldRequired

  const amount = parseFloat(value)
  if (isNaN(amount) || amount <= 0)
    return 'Value should be greater than 0.'

  if (!limit.hasOwnProperty(cryptoCurrency))
    return ''

  const { min, max } = limit[cryptoCurrency]
  if (amount < min || amount > max)
    return `Value should be greater or equal ${min} ${cryptoCurrency} and less than ${max} ${cryptoCurrency}`

  return ''
}

export async function validateWallet(
  isAuthorized: boolean,
  wallet: string,
  cryptoCurrency: string,
  fiatAmount: string,
  fiatCurrency: string,
  msgInvalidWallet: string,
  msgEnterValidWallet: string
) {
  if (wallet === '')
    return 'This field is required.'

  if (!StringUtils.isValidWallet(wallet, cryptoCurrency))
    return msgEnterValidWallet

  if (parseFloat(fiatAmount) > 6000)
    return 'The transaction amount has been exceeded. Please enter a different amount.'

  if (!await ExClient.isValidWallet(wallet, cryptoCurrency))
    return msgInvalidWallet

  if (isAuthorized && !await ExClient.checkLimits(wallet, cryptoCurrency, fiatAmount, fiatCurrency))
    return 'Invalid amount. You have reached the limit of exchanges.'

  return ''
}

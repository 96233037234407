import {EventBus, defineEvent} from "ts-bus";
import {ExClientInfo} from "../ExClientInfo";
import {WalletState} from "../Wallet";

// https://www.npmjs.com/package/ts-bus/v/0.1.7
type ClientEventData = {
    type: string;
    payload: {
        client_id: number;
    };
};

type ClientInfoEventData = {
    type: string;
    payload: ExClientInfo;
};

type clientBalanceEventData = {
    type: string;
    payload: WalletState;
}

export const CLIENT_EVENTS = {
    clientLoggedEvent: defineEvent<ClientEventData>("CLIENT_LOGGED"),
    clientLoggedOutEvent: defineEvent('CLIENT_LOGGED_OUT'),
    clientInfoUpdateEvent: defineEvent<ClientInfoEventData>("CLIENT_INFO_UPD"),
    clientBalanceEvent: defineEvent<clientBalanceEventData>("CLIENT_BALANCE"),
}

export abstract class EventUtils {
    static bus = new EventBus();
}
import { ECardType } from "src/constants/CardConstants";
import { ApiData, IApiResponse, getApiDataPromise } from "../../ApiData";
import { ExClient } from "../../ExClient";
import { SOMETHING_WRONG } from "../../constants/CommonConstants";
import {
  EXCHANGE_TYPE_CRYPTO,
  EXCHANGE_TYPE_FIAT,
} from "../../constants/ExchangeConstants";
import { ExchangeAmountLimit, PaymentType } from "../../types/ExchangeTypes";

export type ExchangePrice = { [_: string]: number };
interface IFees {
  commission: number;
  currency: string;
  symbol: string;
}
export type ExchangePrices = {
  fiat: ExchangePrice;
  crypto: ExchangePrice;
  fees: IFees[];
};
export type ExchangeCurrencies = {
  fiat: string[];
  crypto: string[];
};
export interface UserCards {
  id: string;
  card_pan: string;
}
export const fetchUserCards = async (
  cardType: ECardType
): Promise<IApiResponse<UserCards[]>> => {
  const { success, data, ...resp } = await getApiDataPromise(
    { card_type: cardType },
    "exchange",
    "get_user_cards"
  );
  if (!success) return { success, data, ...resp };
  return { ...resp, success, data };
};

export const fetchExchangePrices = async (
  action?: string
): Promise<ExchangePrices> => {
  const { success, data } = await ApiData.getExchangeRates(action);
  if (!success) return { fiat: {}, crypto: {}, fees: [] };

  return {
    fiat: data.fiat_prices as ExchangePrice,
    crypto: data.crypto_prices as ExchangePrice,
    fees: data.fees as IFees[],
  };
};

const fetchExchangeCurrency = async (
  type: "fiat" | "crypto",
  paymentTypeId = ""
): Promise<string[]> => {
  const fiat = await ApiData.getSelectorList(
    type,
    { param: paymentTypeId },
    false
  );
  return fiat.reduce((acc, { key }) => {
    acc.push(key);
    return acc;
  }, []);
};

export const fetchExchangeCurrencies = async (
  paymentTypeId = ""
): Promise<ExchangeCurrencies> => {
  const fiat = await fetchExchangeCurrency(EXCHANGE_TYPE_FIAT, paymentTypeId);
  const crypto = await fetchExchangeCurrency(
    EXCHANGE_TYPE_CRYPTO,
    paymentTypeId
  );

  return { fiat, crypto };
};

export const fetchPaymentTypes = async (): Promise<PaymentType[]> => {
  const { success, data } = await ApiData.publicApiRequest(
    {
      shop_id: ExClient.shop_id,
      payment_system_id: ExClient.payment_id,
    },
    "get_payment_types"
  );

  return success ? data : [];
};

export const fetchExchangeAmountLimit = async (): Promise<ExchangeAmountLimit> => {
  const { success, data } = await ApiData.publicApiRequest(
    {},
    "get_exchange_amount_limit"
  );
  return success ? data : [];
};

type RateAndCommission = {
  rate: number;
  transaction_fee_pct: number;
};

export const fetchRateAndCommission = async (
  fiatCurrency: string,
  cryptoCurrency: string
): Promise<RateAndCommission> => {
  const { success, data } = await ApiData.exchangeApiRequest(
    {
      shop_id: ExClient.shop_id,
      fiat_symbol: fiatCurrency,
      crypto_symbol: cryptoCurrency,
    },
    "get_rate_and_commission_crypto_symbol"
  );

  return success ? data : { rate: 0, transaction_fee_pct: 0 };
};

export const getIsLoggedIn = async (): Promise<boolean> => {
  const user_id = parseInt(await ExClient.checkLogin()) || 0;
  return user_id !== 0;
};

export const getIsRegistered = async (email: string): Promise<boolean> => {
  const { success, data } = await ApiData.clientApiRequest(
    { email },
    "is_registered",
    null
  );
  return success && data;
};

type VerifyEmail = {
  success: boolean;
  message: string;
};

export const sendVerifyEmail = async (
  email: string,
  wallet: string,
  captcha: string,
  fiat_input: string,
  fiat_select: string,
  crypto_select: string
): Promise<VerifyEmail> => {
  const { success, message } = await ExClient.verifyTempMail({
    email,
    wallet,
    fiat_input,
    fiat_select,
    crypto_select,
    "g-recaptcha-response": captcha,
    s_id: ExClient.shop_id,
    p_id: ExClient.payment_id,
    p_name: ExClient.payment_name,
  });

  return { success, message: message || SOMETHING_WRONG };
};

export const getIsEmailVerified = async (email: string): Promise<boolean> => {
  const { success, data } = await ApiData.clientApiRequest(
    {
      email,
      s_id: ExClient.shop_id,
    },
    "is_verified_temp_email",
    null
  );

  return success && data;
};

type TransactionData = {
  token: string;
  transaction_id: number;
  ex_transaction_id: number;
  self_processing: boolean;
  payment_system_id: number;
};

type Transaction = {
  success: boolean;
  message?: string;
  data?: TransactionData;
};

export const createTransaction = async (
  fiat_amount: string,
  fiat_currency: string,
  crypto_currency: string,
  wallet: string,
  isAgain = false
): Promise<Transaction> => {
  const { success, message, data } = await ApiData.exchangeApiRequest(
    {
      fiat_amount,
      fiat_currency,
      crypto_currency,
      wallet,
      payment_system_id: ExClient.payment_id,
      again: isAgain ? 1 : 0,
    },
    "create"
  );

  if (success) return { success, data };

  return { success, message: message || SOMETHING_WRONG };
};

import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ApiData } from '../ApiData'

type FormData = {
  name: string
  email: string
  phone: string
  subject: string
  message: string
  captcha: string
}

type ContactFormV2Return = {
  onSubmit: (data: FormData) => void
  formMethods: UseFormMethods<FormData>
  captchaRef: React.MutableRefObject<any>
  onCaptchaChange: (value: string) => void
}

export const useContactFormV2 = <TFormValues extends Record<string, any> = Record<string, any>>(): ContactFormV2Return => {
  const formMethods = useForm<FormData>()

  const onSubmit = async (data: FormData) => {
    const { success, message } = await ApiData.publicApiRequest({
      ...data,
      'g-recaptcha-response': data.captcha
    }, 'send_ticket')

    if (!success)
      return toast.error(message)

    toast.success('Thank you for your request!')

    formMethods.reset()
    captchaRef.current.reset()
  }

  const captchaRef = useRef(null)
  const onCaptchaChange = (value: string) => {
    formMethods.setValue('captcha', value, { shouldValidate: true })
  }

  return {
    onSubmit,
    formMethods,
    captchaRef,
    onCaptchaChange,
  }
}

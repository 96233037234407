import { useCallback, useEffect, useState } from 'react'
import { History } from 'history'
import shallow from 'zustand/shallow'
import { ApiData } from '../ApiData'
import { useStoreExchange } from '../store/useStoreExchange'
import { cfgExchange } from '../DefaultValues'
import { Rate } from '../types/ExchangeTypes'

type RatesFiatProps = {
  history: History
  initialCurrency?: string
}

type RatesFiatReturn = {
  rates: Rate[]
  currency: string
  setCurrency: (newValue: string) => void
  onBuyNow: (symbol: string) => void
}

export const useRatesFiat = ({ history, initialCurrency = 'USD' }: RatesFiatProps): RatesFiatReturn => {
  const [rates, setRates] = useState([])
  const [currency, setCurrency] = useState(initialCurrency)

  const [setFiatCurrency, setCryptoCurrency] = useStoreExchange(state => [
    state.setFiatCurrency,
    state.setCryptoCurrency,
  ], shallow)

  const fetchRates = useCallback(async (symbol: string) => {
    let { success, data } = await ApiData.exchangeApiRequest({ symbol }, 'get_rates_fiat_symbol')
    if (!success) return

    setRates(data.reduce((acc: Rate[], cur: Rate) => {
      acc.push({ ...cur, change: parseFloat(cur.change.toString()) })
      return acc
    }, []))
  }, [])

  useEffect(() => {
    fetchRates(currency).then()
  }, [currency, fetchRates])

  const onBuyNow = (symbol: string) => {
    setFiatCurrency(currency)
    setCryptoCurrency(symbol)
    history.push(`/exchange/${cfgExchange.STEPS.STEP1.route}`)
  }

  return {
    rates,
    currency,
    setCurrency,
    onBuyNow,
  }
}

import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ApiData } from '../ApiData'
import { ExClient } from '../ExClient'

type FormData = {
  first_name: string
  last_name: string
  email: string
  skype: string
  country_code: string
  phone: string
  website: string
  visitors_count: string
  captcha: string
}

type AffiliateFormReturn = {
  onSubmit: (data: FormData) => void
  formMethods: UseFormMethods<FormData>
  captchaRef: React.MutableRefObject<any>
  onCaptchaChange: (value: string) => void
}

export const useAffiliateForm = <TFormValues extends Record<string, any> = Record<string, any>>(): AffiliateFormReturn => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      country_code: ExClient.ip_country_code,
    }
  })

  const onSubmit = async (data: FormData) => {
    const { success, message } = await ApiData.publicApiRequest({
      ...data,
      'g-recaptcha-response': data.captcha
    }, 'send_affiliate_mail')

    captchaRef.current.reset()

    if (!success)
      return toast.error(message)

    formMethods.reset()
    toast.success('Thank You for Your request!')
  }

  const captchaRef = useRef(null)
  const onCaptchaChange = (value: string) => {
    formMethods.setValue('captcha', value, { shouldValidate: true })
  }

  return {
    onSubmit,
    formMethods,
    captchaRef,
    onCaptchaChange,
  }
}
